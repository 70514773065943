.otsikko-banneri {
    text-align: center;
    opacity: 80%;
    width: 100%;
    padding: 2rem;
    margin-bottom: 0rem;
}

.Mui-error {
    font-weight: 700;
}